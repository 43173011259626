import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import {
  withLocale, withBetSlipActions, withBetSlip, withFeedbackActions,
} from 'core/hocs';
import { BET_SLIP_STORE_FIELDS, BET_SLIP_STATE } from 'core/constants';
import { BettingTable } from 'components/betting-table/betting-table';
import { MediaQuery } from 'components/media-query/media-query';

import { GameHead } from './game-head/game-head';
import { GameRow } from './game-row/game-row';
import { GameRowMobile } from './game-row-mobile/game-row-mobile';

import './games-table.scss';

class GamesTableUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    isLeagueType: PropTypes.bool,
    games: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    withTableTitle: PropTypes.bool,
    withHeader: PropTypes.bool,
    toggleStake: PropTypes.func.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    className: PropTypes.string,
    isWatchlist: PropTypes.bool,
    updateWatchlist: PropTypes.func,
    isResults: PropTypes.bool,
    isSchedule: PropTypes.bool,
    pageName: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    statuses: PropTypes.shape(),
    streams: PropTypes.shape(),
    markets: PropTypes.shape(),
    toggleOpenTable: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    tableId: PropTypes.number.isRequired,
    isLiveFrozen: PropTypes.bool,
    isPrematchFrozen: PropTypes.bool,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
    sectionBeforeEventPage: PropTypes.string,
    liveSportId: PropTypes.number,
  };

  static defaultProps = {
    isLeagueType: false,
    withTableTitle: true,
    withHeader: true,
    className: null,
    isWatchlist: false,
    updateWatchlist: null,
    isResults: false,
    isSchedule: false,
    statuses: null,
    streams: null,
    markets: null,
    isOpen: true,
    isLiveFrozen: false,
    isPrematchFrozen: false,
    sectionBeforeEventPage: null,
    liveSportId: null,
  };

  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props, nextProps);
  }

  render() {
    const {
      locale,
      toggleStake,
      isLeagueType,
      games,
      withTableTitle,
      withHeader,
      betSlipStakes,
      betSlipState,
      className,
      isWatchlist,
      updateWatchlist,
      isResults,
      isSchedule,
      pageName,
      statuses,
      markets,
      logoUrl,
      logoUrl2,
      streams,
      isOpen,
      toggleOpenTable,
      tableId,
      isLiveFrozen,
      isPrematchFrozen,
      sendLeagueClickFeedback,
      sendEventClickFeedback,
      sectionBeforeEventPage,
      liveSportId,
    } = this.props;

    if (!games) {
      return null;
    }

    const {
      groupDate,
      desc: {
        sport,
        tournament,
      }
    } = games[0];

    const isLeagueClickable = true;

    const gameRowProps = {
      locale,
      isLeagueType,
      toggleStake,
      betSlipStakes,
      betSlipState,
      isWatchlist,
      updateWatchlist,
      isResults,
      isSchedule,
      pageName,
      statuses,
      markets,
      logoUrl,
      logoUrl2,
      isLiveFrozen,
      isPrematchFrozen,
      sendLeagueClickFeedback,
      sendEventClickFeedback,
      sectionBeforeEventPage,
      liveSportId,
    };

    return (
      <Fragment>
        <BettingTable isOpen={isOpen} className={classNames('games-table', className, { 'is-results': isResults })}>
          {withHeader && (
            <MediaQuery down="xs">
              <GameHead
                locale={locale}
                sportId={sport.id}
                leagueId={tournament.id}
                leagueName={tournament.name}
                isLeagueType={isLeagueType}
                groupDate={groupDate}
                withTableTitle={withTableTitle}
                isWatchlist={isWatchlist}
                isClickable={isLeagueClickable}
                tableId={tableId}
                isOpen={isOpen}
                toggleOpenTable={toggleOpenTable}
                sendLeagueClickFeedback={sendLeagueClickFeedback}
              />
            </MediaQuery>
          )}
          {games.map(game => (
            <Fragment key={game.desc.id}>
              <MediaQuery up="sm">
                <GameRow
                  game={game}
                  hasLiveStream={Boolean(streams && streams[game.desc.id])}
                  withTableTitle={withTableTitle}
                  {...gameRowProps}
                />
              </MediaQuery>
              <MediaQuery down="xs">
                <GameRowMobile
                  game={game}
                  hasLiveStream={Boolean(streams && streams[game.desc.id])}
                  setActiveGameId={this.setActiveGameId}
                  isOpen={isOpen}
                  {...gameRowProps}
                />
              </MediaQuery>
            </Fragment>
          ))}
        </BettingTable>
      </Fragment>
    );
  }
}

export const GamesTable = withFeedbackActions(withLocale((withBetSlipActions(withBetSlip(GamesTableUI, [
  BET_SLIP_STORE_FIELDS.STAKES,
  BET_SLIP_STORE_FIELDS.BET_SLIP_STATE,
])))));
