import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import {
  toTimeFormat,
  toTodayOrTomorrow,
  toTimeIfNearOrDate,
  removeComma,
} from 'helpers/date';
import { TODAY, TOMORROW } from '../../../constants';

const DateFieldUI = ({
  locale,
  isTimeFormat,
  timestamp,
}) => {
  const formattedDate = toTodayOrTomorrow(timestamp);

  return (
    isTimeFormat
      ? toTimeFormat(timestamp, locale)
      : (
        <Fragment>
          {formattedDate === TODAY && <FormattedTag id="general.today" className="d-sm-block" />}
          {formattedDate === TOMORROW && <FormattedTag id="general.tomorrow" className="d-sm-block" />}
          {' '}
          <span>{removeComma(toTimeIfNearOrDate(timestamp, locale))}</span>
        </Fragment>
      )
  );
};

DateFieldUI.propTypes = {
  locale: PropTypes.string.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isTimeFormat: PropTypes.bool,
};

DateFieldUI.defaultProps = {
  isTimeFormat: false,
};

export const DateField = React.memo(DateFieldUI);
