import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import qs from 'qs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BET_SLIP_STATE } from 'core/constants';
import { getConfig, getMainLineMarkets, isEmptyOrNil } from 'core/helpers';

import { RouteLink } from 'components/route-link/route-link';
import { TeamColumn } from 'components/betting-table/team-column/team-column';
import { IconLivestreamSmall } from 'components/icons/icon-livestream-small/icon-livestream-small';
import { IconArrowControl } from 'components/icons/icon-arrow-control/icon-arrow-control';
import { DateField } from 'components/betting-table/date-field/date-field';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';
import { GameOutcomeDropdown } from 'components/betting-table/game-outcome-dropdown/game-outcome-dropdown';
import { prepareScore } from 'helpers/score';
import { prepareMarketName } from 'helpers/stake';
import { GA } from 'helpers/ga';
import { prepareLogo } from 'helpers/logo';

import { GameHead } from '../game-head/game-head';
import { WatchlistMenu } from '../watchlist-menu/watchlist-menu';
import { SPORTS_WITH_3WAY_MAIN_MARKET, SPORTS_WITHOUT_LIVE_SCORE, PAGE_NAMES } from '../../../constants';

import './game-row.scss';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

const SCROLL_OFFSET = 100;

export class GameRow extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    game: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    isLeagueType: PropTypes.bool,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isWatchlist: PropTypes.bool.isRequired,
    updateWatchlist: PropTypes.func,
    isResults: PropTypes.bool.isRequired,
    isSchedule: PropTypes.bool.isRequired,
    pageName: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    statuses: PropTypes.shape(),
    markets: PropTypes.shape(),
    hasLiveStream: PropTypes.bool,
    isLiveFrozen: PropTypes.bool.isRequired,
    isPrematchFrozen: PropTypes.bool.isRequired,
    withTableTitle: PropTypes.bool,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
    sectionBeforeEventPage: PropTypes.string,
    liveSportId: PropTypes.number,
  };

  static defaultProps = {
    isLeagueType: false,
    updateWatchlist: null,
    statuses: null,
    markets: null,
    hasLiveStream: false,
    withTableTitle: true,
    sectionBeforeEventPage: null,
    liveSportId: null,
  };

  oddsRef = createRef();
  gameOutcomeFiltersRef = createRef();

  resizeWindowSubsription;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollWidth, clientWidth } = odds;
      this.resizeWindowSubsription = fromEvent(window, 'resize').pipe(debounceTime(200)).subscribe(this.onScroll);

      if (scrollWidth > clientWidth) {
        this.setIsNextShowed(true);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { game: { markets: prevMarkets } } = prevProps;
    const { game: { markets } } = this.props;

    if (!R.equals(prevMarkets, markets)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onRemoveClick = () => {
    const { isWatchlist, updateWatchlist, game } = this.props;

    if (isWatchlist && updateWatchlist) {
      updateWatchlist(game.desc.id);
      GA.event({
        category: 'watchlist',
        label: 'delete-page',
      });
    }
  };

  onClick = () => {
    const {
      isWatchlist,
      isSchedule,
      pageName,
      game: { desc: { id }, isLive, recommendationId },
      sendEventClickFeedback,
    } = this.props;
    let category;
    let label;

    if (isWatchlist) {
      category = 'watchlist';
      label = 'event-click';
    } else if (isSchedule) {
      category = 'calendar';
      label = 'event-click';
    } else if (pageName === PAGE_NAMES.HOME) {
      category = 'go-to-event-page';

      if (isLive) {
        label = 'inplay-section-main-to-event';
      } else {
        label = 'upcoming-section-main-to-event';
      }
    } else if (pageName === PAGE_NAMES.SPORT) {
      category = 'go-to-event-page';

      if (isLive) {
        label = 'inplay-section-sports-to-event';
      } else {
        label = 'upcoming-section-sports-to-event';
      }
    } else if (pageName === PAGE_NAMES.LEAGUE) {
      category = 'go-to-event-page';

      if (isLive) {
        label = 'inplay-section-league-to-event';
      } else {
        label = 'upcoming-section-league-to-event';
      }
    } else if (pageName === PAGE_NAMES.LIVE) {
      category = 'go-to-event-page';
      label = 'main-inplay-to-event';
    }

    if (category && label) {
      GA.event({
        category,
        label,
      });
    }

    sendEventClickFeedback(id, isLive, recommendationId);
  };

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      locale,
      game,
      toggleStake,
      isLeagueType,
      betSlipStakes,
      betSlipState,
      isWatchlist,
      updateWatchlist,
      isResults,
      isSchedule,
      pageName,
      statuses,
      markets: marketsInfo,
      logoUrl,
      logoUrl2,
      hasLiveStream,
      isLiveFrozen,
      isPrematchFrozen,
      withTableTitle,
      sendLeagueClickFeedback,
      sectionBeforeEventPage,
      liveSportId,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;
    const {
      desc: {
        id: eventId,
        sport: { id: sportId },
        tournament: { id: leagueId, name: leagueName },
        competitors,
        scheduled,
        category,
      },
      groupDate,
      score: {
        home_score: homeScore,
        away_score: awayScore,
        home_dismissals: homeDismissals,
        away_dismissals: awayDismissals,
      },
      state: { match_status: eventStatus, clock },
      markets,
      isLive,
      recommendationId,
    } = game;
    const gameUrl = `/event/${eventId}?${qs.stringify(
      {
        isLive,
        sectionBeforeEventPage,
        recommendationId,
      },
      { skipNulls: true }
    )}`;
    let is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId);
    const isFrozen = isLive ? isLiveFrozen : isPrematchFrozen;
    const isClickable = true;
    const LinkComponent = isClickable ? RouteLink : 'span';
    const teamA = competitors[0].name;
    const teamB = competitors[1].name;
    const marketsList = getMainLineMarkets(markets, marketsInfo, sportId, isFrozen);
    const isAnyMarketExists = marketsList.length > 0;
    const teamALogo = prepareLogo({
      logoUrl,
      logoUrl2,
      competitorId: competitors[0].id,
      sportId,
      categorySlug: category.slug,
      countryCode: competitors[0].country_code,
    });
    const teamBLogo = prepareLogo({
      logoUrl,
      logoUrl2,
      competitorId: competitors[1].id,
      sportId,
      categorySlug: category.slug,
      countryCode: competitors[1].country_code,
    });
    const timestamp = scheduled * 1000;

    const oddProps = {
      teamA,
      teamB,
      eventId,
      betSlipState,
      betSlipStakes,
      toggleStake,
      markets: marketsInfo,
      sportId,
      leagueId,
      isLive,
      isResults,
      pageName,
      isWatchlist,
      isSchedule,
      liveSportId,
      recommendationId,
    };

    return (
      <div
        className="game-row-wrapper"
      >
        <GameHead
          locale={locale}
          sportId={sportId}
          leagueId={leagueId}
          leagueName={leagueName}
          isLeagueType={isLeagueType}
          groupDate={groupDate}
          withTableTitle={withTableTitle}
          isWatchlist={isWatchlist}
          isClickable={isClickable}
          isAllowToggle={false}
          sendLeagueClickFeedback={sendLeagueClickFeedback}
        />
        <div className="game-row bg-main-2">
          <div className="betting-table-row d-flex flex-nowrap justify-content-between">
            <LinkComponent
              to={gameUrl}
              locale={locale}
              className={classNames(
                'betting-table-link flex-fill d-flex justify-content-between align-items-center overflow-hidden',
                {
                  'is-clickable': isClickable,
                }
              )}
              onClick={this.onClick}
            >
              <div className="d-flex overflow-hidden">
                <div className="betting-date-field-wrapper d-flex align-items-center justify-content-center flex-shrink-0 text-center text-extra-2">
                  <span
                    className={classNames('betting-date-field', {
                      caption: isLive,
                      upcoming: !isLive,
                    })}
                  >
                    <span className="animate-minute">
                      {isLive ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <GameStatus
                            locale={locale}
                            sportId={sportId}
                            eventStatus={eventStatus}
                            className="game-status label text-break overflow-hidden"
                            statuses={statuses}
                            clock={clock}
                          />
                          {IS_LIVE_STREAMS_ENABLED && hasLiveStream && <IconLivestreamSmall className="mt-0_5" />}
                        </div>
                      ) : (
                        <DateField
                          locale={locale}
                          isTimeFormat={isLeagueType || isResults || isSchedule}
                          timestamp={timestamp}
                        />
                      )}
                    </span>
                  </span>
                </div>

                <div className="d-flex flex-column overflow-hidden">
                  <TeamColumn name={teamA} logo={teamALogo} sportId={sportId} />
                  <TeamColumn name={teamB} logo={teamBLogo} sportId={sportId} className="mt-0_25" />
                </div>
              </div>

              {(isLive || isResults) && !SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                <div className={classNames('d-flex flex-column pr-2 caption font-weight-bold text-right', { 'text-success': !isResults })}>
                  <span className="mb-1">{prepareScore(sportId, homeScore, homeDismissals)}</span>
                  <span>{prepareScore(sportId, awayScore, awayDismissals)}</span>
                </div>
              )}
            </LinkComponent>

            <div className="odds-container position-relative d-none d-sm-flex justify-content-end flex-wrap flex-sm-nowrap flex-shrink-0">
              <div
                ref={this.gameOutcomeFiltersRef}
                className="game-row-odds-wrapper position-absolute"
              >
                <div
                  ref={this.oddsRef}
                  onScroll={this.onScroll}
                  className="game-row-odds-scroll-bar d-flex"
                >
                  {isAnyMarketExists ? (
                    marketsList.map(({
                      marketInfo,
                      marketOutcome, isTotal, isHandicap, specifier, outcomes
                    }, index) => {
                      const { id: marketId, variants } = marketInfo;
                      const marketName = prepareMarketName({
                        marketInfo, teamA, teamB, specifier, marketId,
                      });
                      const isOutcomesDropdown = (isHandicap || isTotal) && Object.keys(outcomes).length > 1;
                      is3way = marketOutcome && Object.values(marketOutcome).length > 2;
                      const isLastMarket = marketsList.length === index + 1;

                      return !isEmptyOrNil(marketOutcome) && (
                        <div
                          key={marketId}
                          className={classNames('game-row-odds-item d-flex flex-column flex-shrink-0 position-relative',
                            {
                              'with-draw': is3way,
                              'with-dropdown': isOutcomesDropdown,
                              'mr-1': !isOutcomesDropdown && !isLastMarket
                            })}
                        >
                          <div className="market-name overflow-hidden caption position-absolute text-center mt-1">
                            <span className="text-nowrap text-extra-3">
                              {marketName}
                            </span>
                          </div>
                          <div className="d-flex align-items-center flex-shrink-0 mt-4">
                            <div className="game-row-odds-group overflow-hidden">
                              {Object.entries(marketOutcome).map(([outcomeId, outcomeValue]) =>
                                outcomeValue ? (
                                  <OddsCell
                                    key={`${eventId}-${marketId}-${specifier}-${outcomeId}`}
                                    stake={outcomeValue}
                                    outcomeId={outcomeId}
                                    marketId={marketId}
                                    isTotal={isTotal}
                                    isHandicap={isHandicap}
                                    specifier={specifier}
                                    variants={variants}
                                    {...oddProps}
                                  />
                                ) : (
                                  <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                                    &#8212;
                                  </div>
                                ))}
                            </div>
                            {isOutcomesDropdown && (
                              <GameOutcomeDropdown
                                gameOutcomeFiltersRef={this.gameOutcomeFiltersRef}
                                className="ml-0_25 mr-0_5"
                                classNameMenu={classNames('game-row-dropdown-menu', { 'is-first': index === 0 })}
                                outcomes={outcomes}
                                marketId={marketId}
                                isTotal={isTotal}
                                isHandicap={isHandicap}
                                specifier={specifier}
                                oddProps={oddProps}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={classNames('game-row-odds-item', { 'with-draw': is3way })}>
                      <div className="game-row-odds-group overflow-hidden">
                        <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                          &#8212;
                        </div>
                        <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                          &#8212;
                        </div>
                        {is3way && (
                          <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                            &#8212;
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className={classNames('game-row-odds-prev position-absolute d-flex align-items-center', { invisible: !isPrevShowed })}>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={this.onPrevClick}
                    onKeyPress={this.onPrevClick}
                    className="game-row-odds-prev-btn d-flex justify-content-center align-items-center position-relative"
                  >
                    <IconArrowControl />
                  </div>
                </div>

                <div className={classNames('game-row-odds-next position-absolute d-flex align-items-center justify-content-end', { invisible: !isNextShowed })}>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={this.onNextClick}
                    onKeyPress={this.onNextClick}
                    className="game-row-odds-next-btn d-flex justify-content-center align-items-center position-relative"
                  >
                    <IconArrowControl />
                  </div>
                </div>
              </div>
            </div>

            {isWatchlist && <WatchlistMenu updateWatchlist={updateWatchlist} game={game} />}
          </div>
        </div>
      </div>
    );
  }
}
